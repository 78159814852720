import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CoverDetails from 'components/sections/cover/cards/CoverDetails';
import HospitalExtrasCoverCombo from 'components/sections/cover/cards/HospitalExtrasCoverCombo';
import PersonsCovered from 'components/sections/cover/cards/PersonsCovered';
import gtagEvent from 'libs/gtagEvent';
import { setEditPersonModalVisible } from 'store';
import EditPersonModal from 'components/modals/PersonsCoveredModals/EditPersonModal/EditPersonModal';
import HRAModal from 'components/modals/HRAModal/HRAModal';
import { StoreType } from 'store/types';

const CoverPage: React.FC = () => {
    const dispatch = useDispatch();
    const uiData = useSelector((state: StoreType) => state.ui);
    const editPersonModalVisible = uiData.editPersonModalVisible

    return (
        <>
            <main className="container-fluid p-4">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-9">
                        <div className="mb-4">
                            <CoverDetails />
                        </div>
                        <HospitalExtrasCoverCombo />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3">
                        {/* Wrapping in an extra div prevents divisions from following
                         The height of neighbor cards */}
                        <div>
                            <PersonsCovered />
                        </div>
                    </div>
                </div>
            </main>
            <EditPersonModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'editperson',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    dispatch(setEditPersonModalVisible(false));
                }}
                show={editPersonModalVisible}
                align={'right'}
            />
            <HRAModal align={'center'} />
        </>
    );
};

export default CoverPage;
