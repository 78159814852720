import { PlaceHolderCard } from 'components/elements/etc/PlaceHolderCard';
import LabelledContent from 'components/elements/labels/LabelledContent';
import { hasCover } from 'libs/booleanHelpers';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import WithHospitalView from './WithHospital/WithHospitalView';

const NoHospital = ({ product }) => {
    if (!product) {
        return null;
    }

    if (Object.keys(product).length === 0 && product.constructor === Object) {
        return (
            <>
                <PlaceHolderCard className="py-4 mt-4 px-4" rows={10} />
            </>
        );
    }

    return (
        <Card className="py-4 mt-4">
            {/* Title */}
            <div className="">
                <LabelledContent label="Hospital Cover" className="px-4">
                    <h1 className="text-label-gray-no">No Hospital</h1>
                </LabelledContent>
            </div>
        </Card>
    );
};

const OverviewCoverHospitalSection = () => {
    const product = useSelector((state) => state.product);
    const showHospital = hasCover(product, 'hospital');

    if (!product?.base?.type) {
        return <PlaceHolderCard className="py-4 mt-4 px-4" rows={10} />;
    }

    if (!showHospital) {
        return <NoHospital />;
    }

    return <WithHospitalView />;
};

export default OverviewCoverHospitalSection;
