import React from 'react';
import styled from 'styled-components';
import { MdCheckCircle, MdError, MdPauseCircleFilled } from 'react-icons/all';

const CoverStatusContainer = styled.div`
    // background-color: #ECF8EA;
    border-radius: 12px;
    display: flex;
    padding: 3px;
    justify-content: start;
    align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
`;

const CoverStatusBadge = ({ membership }) => {
    let iconColor, backgroundColor, text, icon;
    const iconSize = 23;

    if (!membership?.HealthCover?.CoverStatus) {
        return null;
    }
    const generatePaidToDate = (membership) => {
        const paidToDateParts =
            membership?.Contribution?.PaidToDate?.split?.('-');
        const TODAY = new Date();
        const TOMORROW = new Date(TODAY);
        TOMORROW.setDate(TOMORROW.getDate() + 1);

        return !paidToDateParts
            ? TOMORROW
            : new Date(
                  paidToDateParts[0],
                  Number(paidToDateParts[1]) - 1,
                  paidToDateParts[2],
                  23,
                  59,
              );
    };

    const paidToDate = generatePaidToDate(membership);

    let status = membership?.HealthCover?.CoverStatus;

    if (status === 'Active') {
        // check if we are paid up
        const todayDate = new Date();
        if (todayDate > paidToDate) {
            status = 'Overdue';
        }
    }

    switch (status) {
        case 'Active':
            backgroundColor = '#ecf8ea';
            iconColor = 'text-success';
            icon = <MdCheckCircle size={iconSize} />;
            text = 'COVER ACTIVE';
            break;

        case 'Suspended':
            backgroundColor = '#FDF4E1';
            iconColor = 'text-warning';
            icon = <MdPauseCircleFilled size={iconSize} />;
            text = 'COVER SUSPENDED';
            break;

        case 'Overdue':
            backgroundColor = '#FCE3E6';
            iconColor = 'text-danger';
            icon = <MdError size={iconSize} />;
            text = 'PAYMENT OVERDUE';
            break;
        case 'Terminated':
            backgroundColor = '#FCE3E6';
            iconColor = 'text-danger';
            icon = <MdError size={iconSize} />;
            text = 'COVER TERMINATED';
            break;
        default:
            backgroundColor = '#FDF4E1';
            iconColor = 'text-warning';
            icon = <MdPauseCircleFilled size={iconSize} />;
            text = 'UNKNOWN';
            break;
    }

    return (
        <CoverStatusContainer style={{ backgroundColor: backgroundColor }}>
            <div className={iconColor}>{icon}</div>
            <div className="px-1 mr-3 font-feature">{text}</div>
        </CoverStatusContainer>
    );
};

export default CoverStatusBadge;
