import MembershipMessages from "components/elements/banners/MembershipMessages"
import UserSwitcher from "components/elements/etc/UserSwitcher"
import OverviewCoverControlsView from "./cards/OverviewCoverControlsSection/OverviewCoverControlsView"
import AdBlockView from "./cards/advert/components/AdBlockView"
import OverviewCoverHospitalSection from "./cards/coverHospital/OverviewCoverHospitalSection"
import OverviewCoverExtrasSection from "./cards/coverExtras/OverviewCoverExtrasSection"
import HRAModal from "components/modals/HRAModal/HRAModal"
import OverviewAppAdvertSection from "./cards/advert/OverviewAppAdvertSection"
import React from "react"

interface OverviewDesktopProps {
    hospitalClasses: string;
    extrasClasses: string;
}

const OverviewDesktop = ({ hospitalClasses, extrasClasses }: OverviewDesktopProps) => {

    return ( 
        <div>
            <UserSwitcher />
            <main className="container-fluid p-4">
                <MembershipMessages />

                <div className="row">
                    <section className="col-md-12 col-lg-5">
                        <OverviewCoverControlsView />
                    </section>

                    <OverviewAppAdvertSection>
                        <AdBlockView
                            type="ad_block"
                            gtag={{
                                action: 'adblock_learnmore',
                                label: 'Clicked on Adblock CTA button',
                            }}
                        />
                        <AdBlockView
                            internal
                            type="refer_block"
                            linkTo="/member/refer"
                            gtag={{
                                action: 'promo_referfriend',
                                label: 'Clicked on Refer a Friend CTA button',
                            }}
                        />
                    </OverviewAppAdvertSection>
                </div>

                <div className="row">
                    <section className={hospitalClasses}>
                        <OverviewCoverHospitalSection />
                    </section>

                    <section className={extrasClasses}>
                        <OverviewCoverExtrasSection />
                    </section>
                </div>
            </main>
            <HRAModal align={'center'} />
        </div>
    )
}

export default OverviewDesktop;