import React from 'react';
import { useSelector } from 'react-redux';
import { hasCover } from 'libs/booleanHelpers';
import { StoreType } from 'store/types';
import OverviewMobile from 'components/sections/overview/OverviewMobile';
import OverviewDesktop from 'components/sections/overview/OverviewDesktop';
import { useMediaQuery, useTheme } from '@material-ui/core';

const OverviewPage = () => {
    const product = useSelector((state: StoreType) => state.product);
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const showExtras = hasCover(product, 'extras');
    const showHospital = hasCover(product, 'hospital');

    const hospitalClasses = (showHospital === true)
            ? 'col-md-12 mt-lg-0 mb-lg-4 mt-sm-0 col-lg-4'
            : 'col-md-12 mt-lg-0 mt-sm-4 col-lg-8';
    const extrasClasses = (showExtras === true)
            ? 'col-md-12 mt-lg-0 mt-sm-0 col-lg-8'
            : 'col-md-12 mt-lg-0 mb-lg-4 mt-sm-0 col-lg-4';

    if (smallScreen) {
        return (
            <OverviewMobile hospitalClasses={hospitalClasses} extrasClasses={extrasClasses} />
        )
    }

    return (
        <OverviewDesktop hospitalClasses={hospitalClasses} extrasClasses={extrasClasses} />
    );
};

export default OverviewPage;
