import React, { useState } from 'react';
import HospitalInfoMobileModal from './HospitalInfoMobileModal';
import { Button, Card } from 'react-bootstrap';
import LabelledTitle from 'components/elements/labels/LabelledTitle';
import { MdChevronRight } from 'react-icons/md';
import { useSelector } from 'react-redux';

const WithHospitalMobile = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const product = useSelector((state) => state.product);

  return (
    <>
      <Card className="p-4 mt-4">
        <LabelledTitle label="Hospital Cover" title={product?.hospitalName} />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Button
            variant="secondary"
            onClick={() => {
              setModalOpen(true);
            }}>
            Details
            <MdChevronRight
              size={20}
              style={{
                verticalAlign: 'middle',
                marginTop: '-2px',
              }}
            />
          </Button>
        </div>
      </Card>
      <HospitalInfoMobileModal
        handleClose={() => setModalOpen(false)}
        show={modalOpen}
      />
    </>
  );
};

export default WithHospitalMobile;
