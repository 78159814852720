import CustomModal from "components/modals/Modal";
import React from "react";
import HospitalInfo from "./HospitalInfo";

interface HospitalInfoMobileModalProps {
    handleClose: () => void;
    show: boolean;
}

const HospitalInfoMobileModal = ({ handleClose, show }: HospitalInfoMobileModalProps) => {
    return (
        <CustomModal
            handleClose={handleClose}
            title="Extras"
            show={show}
            align="right"
        >
            <HospitalInfo />
        </CustomModal>
    );
}

export default HospitalInfoMobileModal;