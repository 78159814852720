import { useMediaQuery, useTheme } from "@material-ui/core";
import WithExtrasMobile from "./WithExtrasMobile";
import WithExtrasDesktop from "./WithExtrasDesktop";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreType } from "store/types";
import { getTopExtrasBars } from "./helpers";

export interface TopBarType {
    generalDental: any;
    majorDental: any;
    dental: any;
    optical: any;
}

const WithExtrasView = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedUserId = useSelector((state: StoreType) => state.selectedUser);
  const benefitLimitArray = useSelector((state: StoreType) => state.benefitLimits);
    
  const [currentUserBenefits, setCurrentUserBenefits] = useState(false);
  const [topExtrasBars, setTopExtrasBars] = useState<TopBarType | null>(null);

  useEffect(() => {
      console.log({ benefitLimitArray });
      if (benefitLimitArray?.BenefitLimits?.length) {
        const allLimits = benefitLimitArray.BenefitLimits;
        const userLimit = allLimits.find(
          (limits) => limits.PersonId === selectedUserId,
        );
  
        const allSpecialtyClass = allLimits?.SpecialityClasses;
  
        const userSpecialtyClass = userLimit?.SpecialityClasses;
        console.log({ userSpecialtyClass, allSpecialtyClass });
  
        if (userSpecialtyClass) {
          setCurrentUserBenefits(userSpecialtyClass);
          setTopExtrasBars(getTopExtrasBars(userSpecialtyClass));
          return;
        }
        if (allSpecialtyClass) {
          setCurrentUserBenefits(allSpecialtyClass);
          setTopExtrasBars(getTopExtrasBars(allSpecialtyClass));
          return;
        }
      } else {
        setCurrentUserBenefits(false);
        setTopExtrasBars(null);
      }
    }, [selectedUserId, benefitLimitArray]);

    if (smallScreen){
        return (
            <WithExtrasMobile topExtrasBars={topExtrasBars} currentUserBenefits={currentUserBenefits} />
        )
    }

    return (
        <WithExtrasDesktop topExtrasBars={topExtrasBars} currentUserBenefits={currentUserBenefits} />
    )
}

export default WithExtrasView;