import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import WithHospitalMobile from "./WithHospitalMobile";
import WithHospitalDesktop from "./WithHospitalDesktop";

const WithHospitalView = () => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    if (smallScreen) {
        return (
            <WithHospitalMobile />
        );
    }

    return (
        <WithHospitalDesktop />
    );
}

export default WithHospitalView;