import { benefitLimitHelpers } from "libs/benefitLimitHelpers";
import { convertToArray } from "libs/helpers";
import { TopBarType } from "./WithExtrasView";

export const getTopExtrasBars = (currentUserBenefits: any) => {
    const top: TopBarType = {
        generalDental: null,
        majorDental: null,
        dental: null,
        optical: null,
    };
    const dental = benefitLimitHelpers(currentUserBenefits).getSpecialityByClass(currentUserBenefits, 'Den');

    if (dental?.HasGeneralDental) {
        top.generalDental = dental?.BenefitLimitStatuses.find(
            (limits) =>
                limits.BenefitLimitCodeShort === '086' ||
                limits.BenefitLimitCodeShort === '400',
        );
        top.generalDental.SpecialityClassCode = 'Den';
        top.generalDental.BenefitLimitStatuses = {};
        top.generalDental.BenefitLimitStatuses =
            dental?.BenefitLimitStatuses.filter(
                (limits) =>
                    limits.BenefitLimitCodeShort === '086' ||
                    limits.BenefitLimitCodeShort === '400',
            );
    }
    if (dental?.HasMajorDental) {
        top.majorDental = dental?.BenefitLimitStatuses.find(
            (limits) => limits.BenefitLimitCodeShort === '087',
        );
        top.majorDental.SpecialityClassCode = 'Den';
        top.majorDental.BenefitLimitStatuses = {};
        top.majorDental.BenefitLimitStatuses =
            dental?.BenefitLimitStatuses.filter(
                (limits) =>
                    limits.BenefitLimitCodeShort !== '086' &&
                    limits.BenefitLimitCodeShort !== '400',
            );
    }
    console.log({ top });
    if (dental?.HasOverallDental) {
        top.dental = dental;
        top.dental.overall = convertToArray(dental?.BenefitLimitStatuses).find(
            (limits) =>
                limits.BenefitLimitCodeShort === '500' ||
                limits.BenefitLimitCodeShort === '400',
        );
        if (top.dental.overall) {
            top.dental.ClaimedAmount = top.dental.overall.ClaimedAmount;
            top.dental.ClaimableAmount = top.dental.overall?.ClaimableAmount;
            top.dental.ClaimableTotal = top.dental.overall?.ClaimableTotal;
        }
    }
    if (
        !dental?.HasGeneralDental &&
        !dental?.HasMajorDental &&
        !dental?.HasOverallDental
    ) {
        top.dental = dental;
    }

    const optical = benefitLimitHelpers(currentUserBenefits).getSpecialityByClass(currentUserBenefits, 'Opt');
    top.optical = optical;
    return top;
}