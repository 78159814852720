import { PlaceHolderCard } from 'components/elements/etc/PlaceHolderCard';
import LabelledContent from 'components/elements/labels/LabelledContent';
import React from 'react';
import { Card } from 'react-bootstrap';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useSelector } from 'react-redux';
import { hasCover } from 'libs/booleanHelpers';
import WithExtrasView from './WithExtras/WithExtrasView';

const NoExtras = () => {
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <Card className="">
            <LabelledContent label="Extras Cover" className="p-4">
              <h1 className="text-label-gray-no">No Extras</h1>
            </LabelledContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

const OverviewCoverExtrasSection = () => {
  const product = useSelector((state) => state.product);
  const membership = useSelector((state) => state.membership);
  const showExtras = hasCover(product, 'extras');

  if (!product?.base?.type) {
    return <PlaceHolderCard className="py-4 mt-4 px-4" rows={10} />;
  }

  if (membership?.HealthCover?.CoverStatus !== 'Active' || !showExtras) {
    return <NoExtras />;
  }

  return <WithExtrasView product={product} />;
};

export default OverviewCoverExtrasSection;
