import { useMediaQuery, useTheme } from "@material-ui/core";
import AdBlockDesktop from "./AdBlockDesktop";
import AdBlockMobile from "./AdBlockMobile";
import React from "react";


const AdBlockView = (props) => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.up('sm'));

  if (mediumScreen){
    return (
        <AdBlockDesktop {...props} />
    )
  }

  return (
        <AdBlockMobile {...props} />
  )
}

export default AdBlockView;