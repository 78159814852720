import CoverStatusBadge from 'components/elements/badges/CoverStatusBadge';
import PaymentMethodBadge from 'components/elements/badges/PaymentMethodBadge';
import CardVerticalBorderContainer from 'components/elements/divisions/CardVerticalBorderContainer';
import Label from 'components/elements/labels/Label';
import LabelledContent from 'components/elements/labels/LabelledContent';
import LabelledTextBlock from 'components/elements/labels/LabelledTextBlock';
import LabelRebate from 'components/elements/labels/LabelRebate';
import GovernmentRebateModal from 'components/modals/GovernmentRebateModal/GovernmentRebateModal';
import MakeAContributionModal from 'components/modals/MakeAContributionModal/MakeAContributionModal';
import {
    expandMembershipType,
    getPaymentMethodDebit,
    ymdToDate,
} from 'libs/helpers';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { MdEdit } from 'react-icons/all';
import { MdChevronRight } from 'react-icons/md';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useSelector } from 'react-redux';
import gtagEvent from 'libs/gtagEvent';
import { getPaymentObj } from 'libs/premiumHelpers';
import { StoreType } from 'store/types';

const CoverDetails = () => {
    const [makeAContributionModalVisible, setMakeAContributionModalVisible] =
        useState(false);
    const [governmentRebateModalVisible, setGovernmentRebateModalVisible] =
        useState(false);

    const membership = useSelector((state: StoreType) => state.membership);
    const product = useSelector((state: StoreType) => state.product);
    const premium = useSelector((state: StoreType) => state.premium);

    const payment = getPaymentObj(premium, membership?.Contribution?.Frequency);

    return (
        <>
            <Card className="pt-4 pb-2 h-100">
                <h1 className="mb-4 px-4 text-primary text-uppercase mx-1">
                    Cover Details
                </h1>

                <CardVerticalBorderContainer>
                    <div className="row mx-4 flex-wrap justify-content-start">
                        <div className={"my-2 col-12 col-md-4"}>
                            <LabelledTextBlock
                                label="Member Number"
                                text={(
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                            ready={!!membership?.MembershipId}
                                            type='text'
                                        >
                                            {membership?.MembershipId}
                                        </ReactPlaceholder>
                                    )}
                            />
                        </div>

                        <div className={"my-2 col-12 col-md-4"}>
                            <LabelledTextBlock
                                label="Membership Type"
                                text={(
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                            ready={!!expandMembershipType(
                                                product?.membershipClassCode,
                                            )}
                                            type='text'
                                        >
                                            {expandMembershipType(product?.membershipClassCode)}
                                        </ReactPlaceholder>
                                    )}
                            />
                        </div>

                        {/* Join Date */}
                        <div className={"my-2 col-12 col-md-4"}>
                            <LabelledTextBlock
                                label="Join Date"
                                text={(
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                            ready={!!ymdToDate(
                                                membership?.HealthCover?.JoinDate,
                                            )}
                                            type='text'
                                        >
                                            {ymdToDate(membership?.HealthCover?.JoinDate)}
                                        </ReactPlaceholder>
                                    )}
                            />
                        </div>

                        {/* Cover Start Date */}
                        <div className={"my-2 col-12 col-md-4"}>
                            <LabelledTextBlock
                                label="Cover Start Date"
                                text={(
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                            ready={!!ymdToDate(
                                                membership?.HealthCover?.EffectiveDate,
                                            )}
                                            type='text'
                                        >
                                            {ymdToDate(membership?.HealthCover?.EffectiveDate)}
                                        </ReactPlaceholder>
                                    )}
                            />
                        </div>

                        {/* Rebate Tier */}
                        <div className={"my-2 col-12 col-md-4"}>
                            <LabelledContent label="Rebate Tier">
                                <div className="font-feature d-flex align-items-center">
                                    <LabelRebate membership={membership} />
                                    <div className="ml-2">
                                        {membership?.Contribution
                                            ?.RebateStatus !==
                                            'Unregistered' && (
                                                <Button
                                                    onClick={() => {
                                                        gtagEvent({
                                                            screen: 'cover',
                                                            action: 'government_rebate',
                                                            label: 'Clicked edit rebate tier button (pencil)',
                                                            type: 'modal_open',
                                                        });
                                                        setGovernmentRebateModalVisible(
                                                            true,
                                                        );
                                                    }}
                                                    variant="link m-0 p-0 d-flex align-items-center">
                                                    <MdEdit />
                                                </Button>
                                            )}
                                    </div>
                                </div>
                            </LabelledContent>
                        </div>
                    </div>
                </CardVerticalBorderContainer>

                <div className="row justify-content-between mx-4 flex-wrap">
                    <div className={"my-2 col-12 col-md-4"}>
                        <CoverStatusBadge membership={membership} />
                    </div>

                    <div className={"my-2 col-12 col-md-4"}>
                        <LabelledContent label="Next Payments">
                            <strong className="font-feature">
                                {(!payment?.NetAmount
                                    ? ''
                                    : `$` + payment?.NetAmount) || (
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                            ready={!!payment?.NetAmount}
                                            type='text'
                                        >
                                            <></>
                                        </ReactPlaceholder>
                                    )}
                            </strong>
                        </LabelledContent>
                    </div>

                    <div className={"my-2 col-12 col-md-4"}>
                        <LabelledContent label="Due Date">
                            <strong className="font-feature">
                                {ymdToDate(
                                    membership?.Contribution?.DueDate,
                                ) || (
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                            ready={!!ymdToDate(
                                                membership?.Contribution?.DueDate,
                                            )}
                                            type='text'
                                        >
                                            <></>
                                        </ReactPlaceholder>
                                    )}
                            </strong>
                        </LabelledContent>
                    </div>
                    <div className={"my-2 col-12 col-md-4"}>
                        <LabelledContent label="Cover Paid To">
                            <strong className="font-feature">
                                {ymdToDate(
                                    membership?.Contribution?.PaidToDate,
                                ) || (
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                            ready={!!ymdToDate(
                                                membership?.Contribution?.PaidToDate,
                                            )}
                                            type='text'
                                        >
                                            <></>
                                        </ReactPlaceholder>
                                    )}
                            </strong>
                        </LabelledContent>
                    </div>
                    <div className={"my-2 col-12 col-md-4"}>
                        <div className="d-flex flex-column">
                            <PaymentMethodBadge
                                method={getPaymentMethodDebit(membership)}
                            />
                            <Label
                                text={
                                    membership?.Contribution?.Frequency || (
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                            ready={!!membership?.Contribution?.Frequency}
                                            type='text'
                                        >
                                            <></>
                                        </ReactPlaceholder>
                                    )
                                }
                            />
                        </div>
                    </div>

                    <div className={"my-2 col-12 col-md-4"}>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'cover',
                                    action: 'make_a_contribution',
                                    label: 'Clicked Pay Now button',
                                    type: 'modal_open',
                                });
                                setMakeAContributionModalVisible(true);
                            }}>
                            PAY NOW
                            <MdChevronRight
                                size={20}
                                style={{
                                    verticalAlign: 'middle',
                                    marginTop: '-2px',
                                }}
                            />
                        </Button>
                    </div>
                </div>
            </Card>

            {/* Modals */}
            <MakeAContributionModal
                handleClose={() => {
                    setMakeAContributionModalVisible(false);
                }}
                show={makeAContributionModalVisible}
                align={'right'}
            />

            <GovernmentRebateModal
                handleClose={() => {
                    setGovernmentRebateModalVisible(false);
                }}
                show={governmentRebateModalVisible}
                align={'right'}></GovernmentRebateModal>
        </>
    );
};

export default CoverDetails;
