import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import {
    fastClaimActions,
    thunkGetBenefitLimits,
    thunkGetClaimsHistory,
} from 'store';
import gtagEvent from 'libs/gtagEvent';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { getSpecialityClassCodeObjectFromDescription } from 'libs/specialityClassCodes';

const AHB_CONST = Object.freeze({
    CODES: {
        DENTAL: 'AHBDE',
        PHYSIO: 'AHBPY',
        OPTICAL: 'AHBOP',
        OTHER: 'AHBCS',
    },
    OPTICAL_ITEMS: [
        'FRAME',
        'LENSV',
        'LENSP',
        'LENSB',
        'LENTR',
        'LENSD',
        'LENSS',
        'COMGL',
        'OPTRE',
    ],
    DENTAL_CLASS: 'D',
    PHYSIO_PREFIX: 'PHYS',
    PHYSIO_PREFIX2: 'EXPHY',
});

const FinaliseClaim = ({ documentsData }) => {
    const fastClaimStore = useSelector((state) => state.fastClaim);
    const ahb = useSelector((state) => state.ahb);
    const dispatch = useDispatch();
    const history = useHistory();
    const specialties = useSelector((state) => state.claimSpeciality);

    useEffect(() => {
        if (fastClaimStore.currentStep === '2') {
            // console.log('HERE');
            benefitQuote(fastClaimStore);
        }
    }, [fastClaimStore.currentStep]);

    useEffect(() => {
        // Ensure we have both of these things
        // AHB is a slow call, so it may not be ready yet

        // 1. Create a list of people on this claim
        // 2. Create a list of people who have > 0 ahb
        // 3. Find common people
        // 4. If this common list has at least one person,
        // then show ahb switch
        if (ahb !== null && fastClaimStore.benefitQuote !== null) {
            const peopleInQuote = getPeopleInQuote(fastClaimStore.benefitQuote);
            const peopleWithAhb = getPeopleWithAhb(ahb);

            const matchingPeople = [];
            peopleInQuote.forEach((person) => {
                if (peopleWithAhb.includes(person)) {
                    if (ahb[person]?.Benefit > 0) {
                        matchingPeople.push(person);
                    }
                }
            });

            dispatch(
                fastClaimActions.review.set.ahb.eligible(
                    matchingPeople.length > 0,
                ),
            );
        }
    }, [fastClaimStore.benefitQuote, ahb]);

    const getPeopleInQuote = (quote) => {
        return quote.map((line) => line.PersonId);
    };

    // Return an array of people
    // who have > 0 ahb
    const getPeopleWithAhb = (ahb) => {
        const people = [];
        Object.keys(ahb).forEach((key) => {
            if (ahb[key].Benefit > 0) {
                people.push(key);
            }
        });
        return people;
    };

    // Use the constant to get the correct AHB code for a given item
    const getAHBCode = (AHB_CONST, item) => {
        if (item.Service.ServiceClassCode === AHB_CONST.DENTAL_CLASS) {
            return AHB_CONST.CODES.DENTAL;
        } else if (AHB_CONST.OPTICAL_ITEMS.includes(item.Service.ServiceCode)) {
            return AHB_CONST.CODES.OPTICAL;
        } else if (
            item.Service.ServiceCode.startsWith(AHB_CONST.PHYSIO_PREFIX)
        ) {
            return AHB_CONST.CODES.PHYSIO;
        } else if (
            item.Service.ServiceCode.startsWith(AHB_CONST.PHYSIO_PREFIX2)
        ) {
            return AHB_CONST.CODES.PHYSIO;
        } else {
            return AHB_CONST.CODES.OTHER;
        }
    };

    const [benefitQuoteState, benefitQuote] = useAsyncFn(
        async (fastClaimStore) => {
            if (fastClaimStore.items.length === 0) {
                return;
            }
            let out = [];
            fastClaimStore.items.forEach((item) => {
                let temp = {};
                temp.PersonId = item.personId;
                temp.ServiceDate = item.dateOfService;
                temp.ProviderCode = item.provider.providerid;
                temp.NumberOfServices = '1';
                temp.Fee = item.itemFee;
                temp.ServiceClassCode = item.item.ServiceClassCode;
                temp.ServiceCode = item.item.ServiceCode;
                if (item.item.IsBodyPartRequired) {
                    temp.BodyPartCode = item.itemBodyPart;
                }
                out.push(temp);
            });

            const memberid = getMembershipId();
            const access_token = getAccessToken();

            try {
                dispatch(fastClaimActions.amount.set.outOfPocket(''));
                dispatch(fastClaimActions.amount.set.benefit(''));

                const response = await api
                    .user(CONFIG, access_token)
                    .benefits.makeBenefitClaimQuote(memberid, out);
                if (response?.status === 'error') {
                    throw new Error(response.message);
                } else {
                    console.log(response);
                    let tempBenefitAmount = parseFloat(0);
                    response.forEach((item) => {
                        let tempAmount = parseFloat(item.Benefit);
                        tempBenefitAmount = tempBenefitAmount + tempAmount;
                        // Add status back to items
                        // TODO: XXX
                    });
                    dispatch(
                        fastClaimActions.amount.set.benefit(
                            tempBenefitAmount.toFixed(2),
                        ),
                    );
                    const tempDiff =
                        parseFloat(fastClaimStore.amountTotal) -
                        parseFloat(tempBenefitAmount);
                    dispatch(
                        fastClaimActions.amount.set.outOfPocket(
                            tempDiff.toFixed(2),
                        ),
                    );
                    dispatch(
                        fastClaimActions.amount.set.benefitQuote(response),
                    );
                }
            } catch (e) {
                console.log('ERROR');
                console.log(e);
            }
        },
    );

    const constructSubmittableObject = (claimLines, fastClaimStore) => {
        const submittable = {
            claimdoc: [],
            lines: claimLines,
            portal: '1',
        };
        console.log({ fastClaimStore });
        fastClaimStore.claimAhb && (submittable.ahb = '1');
        fastClaimStore.agreeDisclaimers && (submittable.declare = '1');

        // One time bank account, optional
        if (fastClaimStore.useOneTimeBankAccount) {
            submittable.tempBank = {
                accountname: fastClaimStore.oneTimeBankAccount.name,
                bsb: fastClaimStore.oneTimeBankAccount.bsb,
                accountno: fastClaimStore.oneTimeBankAccount.acc,
            };
        }

        return submittable;
    };

    const [claimSubmitState, claimSubmit] = useAsyncFn(
        async (fastClaimStore, ahb, specialties, documentsData) => {
            if (fastClaimStore.items.length === 0) {
                return;
            }
            const claimLines = constructSubmissionClaimLines(
                fastClaimStore,
                ahb,
            );
            const submittableObject = constructSubmittableObject(
                claimLines,
                fastClaimStore,
            );

            let dataArray = [];

            submittableObject.ahb &&
                dataArray.push({ name: 'ahb', data: '1' });
            submittableObject.declare &&
                dataArray.push({ name: 'declare', data: '1' });

            let highRisk = false;
            fastClaimStore.items.forEach((item) => {
                const code = getSpecialityClassCodeObjectFromDescription(
                    specialties,
                    item.serviceTypeCode
                );
                if (code?.HighRisk || item?.highRisk || item?.item?.HighRisk) {
                    highRisk = true;
                }
            });

            highRisk &&
                dataArray.push({
                    name: 'highrisk',
                    data: '1',
                });

            submittableObject.portal &&
                dataArray.push({
                    name: 'portal',
                    data: '1',
                });

            submittableObject.lines &&
                dataArray.push({
                    name: 'lines',
                    data: JSON.stringify(submittableObject.lines),
                });
            // console.log({documentsArray});
            const formData = new FormData();
            dataArray.forEach((item) => {
                formData.append(item.name, item.data);
            });

            const memberid = getMembershipId();
            const access_token = getAccessToken();

            try {
                const docFormData = new FormData();
                for (const item of documentsData) {
                    docFormData.append(`claimdoc[${documentsData.indexOf(item)}]`, item.file, item.fileName);
                }

                const responseUpload = await api
                    .user(CONFIG, access_token)
                    .membership.claim.makeFasterClaimUpload(memberid, docFormData)

                formData.append('attachments', JSON.stringify(responseUpload.attachments));
                formData.append('image_count', responseUpload.image_count);

                const claimController = new AbortController();
                const claimTimeoutId = setTimeout(() => claimController.abort(), 120000); 

                const response = await api
                    .user(CONFIG, access_token)
                    .membership.claim.makeFasterClaim(memberid, formData, claimController.signal);

                clearTimeout(claimTimeoutId);
                if (response?.status === 'error') {
                    throw new Error(response.message);
                } else {
                    console.log(response);
                    // Update benefit limits
                    dispatch(thunkGetBenefitLimits());
                    // Update claim history
                    dispatch(thunkGetClaimsHistory());

                    // Send the return from
                    dispatch(fastClaimActions.general.claimResult(response));

                    // Now go to the thankyou page
                    history.push('/member/claim/thankyou');
                }
            } catch (e) {
                console.log('ERROR');
                console.log(e);
            }

            // history.push('/member/claim/thankyou');
        },
    );

    // Construct the claim lines that will be used for the actual claim
    // For each item:
    // 1. Add the claim line
    // 2. Check if this person has any ahb left
    // 3. If so, add an additional line that claims as much as possible
    // using the AHB
    const constructSubmissionClaimLines = (fastClaim, originalAhb) => {
        // First make a non-referential copy of the ahb
        // We need this so that we can 'whittle it down'
        // by using it to create new claim lines.
        const ahb = JSON.parse(JSON.stringify(originalAhb));

        // Must iterate over the lines in the benefit quote
        // and NOT the regular lines by themselves, because we need
        // to know how much benefit will be paid for each of these things

        // There must be at least one item
        if (!fastClaim.benefitQuote?.length > 0) return;

        const claimLines = [];

        fastClaim.benefitQuote.forEach((item) => {
            const newItemClaimLine = {
                PersonId: item.PersonId,
                ServiceDate: item.ServiceDate,
                NumberOfServices: item.NumberOfServices,
                Fee: item.Fee,
                IsFeePaidByClaimant: true,
                ProviderCode: item.ProviderCode,
                ServiceClassCode: item.Service.ServiceClassCode,
                ServiceCode: item.Service.ServiceCode,
            };

            if (item.BodyPartCode) {
                newItemClaimLine.BodyPartCode = item.BodyPartCode;
            }

            // Add to list of claims
            claimLines.push(newItemClaimLine);

            let thisPersonAhb = ahb[item.PersonId].Benefit;
            const personHasNonZeroAhb = thisPersonAhb > 0;
            const claimOutOfPocketAmount = item.Fee - item.Benefit;

            // If this person has AHB, and there is an out of pocket, and they toggled AHB on
            // AND this is not pharmacy
            if (
                personHasNonZeroAhb &&
                claimOutOfPocketAmount > 0 &&
                fastClaim.claimAhb &&
                !item.Service.ServiceCode !== 'PHMCY' &&
                item.Message.ShortText === 'Approved'
            ) {
                // Attempt to cover with AHB
                let ahbDifference = thisPersonAhb - claimOutOfPocketAmount;
                let ahbClaimLineAmount;

                // Make sure the ahbClaimLineAmount does not excess thisPersonAhb
                if (ahbDifference >= 0) {
                    ahbClaimLineAmount = claimOutOfPocketAmount;
                } else {
                    ahbClaimLineAmount = claimOutOfPocketAmount + ahbDifference;
                }

                if (ahbClaimLineAmount > 0) {
                    // Modify the benefit
                    ahb[item.PersonId].Benefit =
                        thisPersonAhb - ahbClaimLineAmount;

                    const ahbCode = getAHBCode(AHB_CONST, item);

                    const newAhbClaimLine = {
                        PersonId: item.PersonId,
                        ServiceDate: item.ServiceDate,
                        NumberOfServices: item.NumberOfServices,
                        Fee: String(ahbClaimLineAmount.toFixed(2)),
                        IsFeePaidByClaimant: true,
                        ProviderCode: item.ProviderCode,
                        ServiceClassCode: 'F',
                        ServiceCode: ahbCode,
                    };

                    // Add to list of claims
                    claimLines.push(newAhbClaimLine);
                }
            }
        });

        return claimLines;
    };

    return (
        <>
            <div className="row finalise-claim-section">
                <div className="w-100">
                    <div className="p-4">
                        <div
                            className="pb-3"
                            style={{
                                display: fastClaimStore.eligibleForAhb
                                    ? 'block'
                                    : 'none',
                            }}>
                            <Form>
                                <Form.Check
                                    type="checkbox"
                                    id={`checkbox-use-ahb`}
                                    label={`I wish to claim AHB`}
                                    style={{
                                        fontSize: '13px',
                                    }}
                                    name={`claim-ahb`}
                                    checked={fastClaimStore.claimAhb}
                                    onChange={(val) => {
                                        dispatch(
                                            fastClaimActions.review.set.ahb.claim(
                                                val.target.checked,
                                            ),
                                        );
                                    }}
                                />
                            </Form>
                        </div>
                        <div style={{ fontSize: '14px' }}>
                            <span
                                className="text-primary-dark-1 font-weight-bold"
                                style={{}}>
                                Disclaimers:
                            </span>{' '}
                            Please answer the following questions to submit your
                            claim
                        </div>
                        <div className="pb-3">
                            <ul>
                                <li>I am authorised to make this claim</li>
                                <li>
                                    I have incurred these expenses and the
                                    information supplied is true and correct
                                </li>
                                <li>
                                    I authorise my Provider to release
                                    information in relation to this claim to TUH
                                    in accordance with the TUH Fund rules, for
                                    the purpose of ensuring benefits
                                </li>
                                <li>
                                    The services being claimed cannot be claimed
                                    from another source
                                </li>
                                <li>
                                    I certify I have paid for services being
                                    claimed and will keep my receipts for twelve
                                    (12) months to send to TUH if selected for
                                    audit (Only services that have been paid for
                                    can be claimed through Online Claims and you
                                    must keep receipts for auditing purposes).
                                </li>
                                <li>
                                    I confirm that this is not a Workers
                                    Compensation Claim (If the costs involved in
                                    this claim are part of workers compensation
                                    you must claim through workcover).
                                </li>
                                <li>
                                    If the costs involved in this claim are
                                    covered via third party insurance or damages
                                    TUH payments of this claim are subject to
                                    refund on settlement of your damages claim
                                    (I agree to refund all TUH payments on
                                    settlement of my damages claim if covered by
                                    third party insurance or damages).
                                </li>
                                <li>I have attached an official itemised receipt for this claim</li>
                            </ul>
                        </div>
                        <div className="pb-3">
                            <Form>
                                <Form.Check
                                    type="checkbox"
                                    id={`checkbox-agree`}
                                    label={`I agree with all of the disclaimers above *`}
                                    style={{
                                        fontSize: '13px',
                                    }}
                                    name={`disclaimers-agree`}
                                    checked={fastClaimStore.agreeDisclaimers}
                                    onChange={(val) => {
                                        gtagEvent({
                                            screen: 'fastclaim',
                                            action: 'three_disclaimercheck',
                                            label: 'User toggled disclaimer consent',
                                        });
                                        dispatch(
                                            fastClaimActions.review.set.agreeDisclaimers(
                                                //TODO: should this be val.target.value?
                                                val.target.checked,
                                            ),
                                        );
                                    }}
                                />
                            </Form>
                        </div>
                    </div>
                    <div
                        style={{
                            boxShadow: 'inset 0px 2px 0px #E6E6EE',
                        }}>
                        <div className="px-4 pt-3">
                            <div className="" style={{ fontSize: '14px' }}>
                                Your claim has been assessed subject to your claim limits.
                            </div>
                        </div>
                        <div className="row px-4 py-3">
                            <div className="col-md-2">
                                <div
                                    className="text-uppercase text-light-gray font-weight-medium"
                                    style={{
                                        fontSize: '11px',
                                    }}>
                                    TOTAL
                                </div>
                                <div
                                    className="text-uppercase text-black font-weight-bold"
                                    style={{
                                        fontSize: '22px',
                                    }}>
                                    {fastClaimStore.amountTotal !== '' ? (
                                        `$${parseFloat(
                                            fastClaimStore.amountTotal,
                                        ).toFixed(2)}`
                                    ) : (
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div
                                    className="text-uppercase text-light-gray font-weight-medium"
                                    style={{
                                        fontSize: '11px',
                                    }}>
                                    BENEFITS
                                </div>
                                <div
                                    className="text-uppercase font-weight-bold"
                                    style={{
                                        fontSize: '22px',
                                        color: '#429F33',
                                    }}>
                                    {fastClaimStore.amountBenefit !== '' ? (
                                        `$${parseFloat(
                                            fastClaimStore.amountBenefit,
                                        ).toFixed(2)}`
                                    ) : (
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div
                                    className="text-uppercase text-light-gray font-weight-medium"
                                    style={{
                                        fontSize: '11px',
                                    }}>
                                    OUT OF POCKET
                                </div>
                                <div
                                    className="text-uppercase text-black font-weight-bold"
                                    style={{
                                        fontSize: '22px',
                                    }}>
                                    {fastClaimStore.amountOutOfPocket !== '' ? (
                                        `$${parseFloat(
                                            fastClaimStore.amountOutOfPocket,
                                        ).toFixed(2)}`
                                    ) : (
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6 d-flex flex-row-reverse">
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        gtagEvent({
                                            screen: 'fastclaim',
                                            action: 'three_submitclaim',
                                            label: 'submitted claim on step three of fast claim',
                                        });
                                        // benefitQuote(providerID,benefitService,selectedPerson,benefitItems,totalAmount);
                                        claimSubmit(
                                            fastClaimStore,
                                            ahb,
                                            specialties,
                                            documentsData
                                        );
                                    }}
                                    disabled={
                                        !fastClaimStore.agreeDisclaimers ||
                                        benefitQuoteState.loading ||
                                        claimSubmitState.loading
                                    }
                                    className="mr-3 align-self-baseline">
                                    {claimSubmitState.loading ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                role="status"
                                                as="span"
                                                size="sm"
                                            />
                                            Submit Claim
                                        </>
                                    ) : (
                                        'Submit Claim'
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FinaliseClaim;
