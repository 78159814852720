import React from 'react'

const OverviewAppAdvertSection = ({ children }) => {
    return (
        <section className="col-md-12 mt-lg-0 mt-sm-4 col-lg-7 ad-container">
            {children}
        </section>
    )
};

export default OverviewAppAdvertSection;
