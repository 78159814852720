import ExtrasLimitsModal from "components/modals/ExtrasLimitsModal/ExtrasLimitsModal";
import gtagEvent from "libs/gtagEvent";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import ExtrasInfo from "./ExtrasInfo";

const WithExtrasDesktop = ({ topExtrasBars, currentUserBenefits }) => {
    const [extrasModalVisible, setExtrasModalVisible] = useState(false);
    const [currentExtraBenefit, setCurrentExtraBenefit] = useState(false);
  
    const handleExtraClick = (extra, name) => {
      setExtrasModalVisible(true);
      const extraName = name || extra.name;
      const eventName = extraName?.toLowerCase().replace(' ', '_') || 'extra_cover';
  
      gtagEvent({
        screen: 'overview',
        action: eventName + '_tile',
        label: `Clicked on a ${extraName} button`,
        type: 'modal_open',
      });
      setCurrentExtraBenefit({
        ...extra,
        eventName,
        name: extraName,
      });
    };
  
    return (
      <div>
        <div className="row mt-4">
          <div className="col">
            <Card className="p-4">
              <ExtrasInfo topExtrasBars={topExtrasBars} handleExtraClick={handleExtraClick} currentUserBenefits={currentUserBenefits} />
            </Card>
          </div>
        </div>
        <ExtrasLimitsModal
          title={
            currentExtraBenefit.name
              ? currentExtraBenefit.name
              : currentExtraBenefit.Description
          }
          show={extrasModalVisible}
          handleClose={() => {
            gtagEvent({
              screen: currentExtraBenefit.eventName,
              action: 'cancel',
              label: 'Cancelled or closed modal',
              type: 'modal',
            });
            setExtrasModalVisible(false);
            setCurrentExtraBenefit({});
          }}
          currentExtraBenefit={currentExtraBenefit}></ExtrasLimitsModal>
      </div>
    );
  };

export default WithExtrasDesktop;