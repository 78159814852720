import React from "react";
import HospitalInfo from "./HospitalInfo";
import { Card } from "react-bootstrap";

const WithHospitalDesktop = () => {

    return (
        <Card className="py-4 mt-4">
            <HospitalInfo />
        </Card>
    )
}

export default WithHospitalDesktop