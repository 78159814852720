import CustomModal from "components/modals/Modal";
import ExtrasInfo from "./ExtrasInfo";
import React from "react";

interface ExtrasInfoMobileModalProps {
    handleClose: () => void;
    show: boolean;
    topExtrasBars: any;
    handleExtraClick: any;
    currentUserBenefits: any;
}

const ExtrasInfoMobileModal = ({ handleClose, show, topExtrasBars, handleExtraClick, currentUserBenefits }: ExtrasInfoMobileModalProps) => {

    return (
        <CustomModal
            handleClose={handleClose}
            title="Extras"
            show={show}
            align="right"
        >
            <ExtrasInfo handleExtraClick={handleExtraClick} topExtrasBars={topExtrasBars} currentUserBenefits={currentUserBenefits} />
        </CustomModal>

    )
}

export default ExtrasInfoMobileModal;