import { useMediaQuery, useTheme } from "@material-ui/core";
import OverviewCoverControlsMobile from "./OverviewCoverControlsMobile";
import OverviewCoverControlsDesktop from "./OverviewCoverControlsDesktop";
import React from "react";


const OverviewCoverControlsView = (props) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    if (smallScreen) {
        return (
            <OverviewCoverControlsMobile {...props} />
        )
    }

    return (
        <OverviewCoverControlsDesktop {...props} />
    )
}

export default OverviewCoverControlsView;