import { Tab } from 'react-bootstrap';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import gtagEvent from 'libs/gtagEvent';
// import { allAdults } from 'libs/helpers';
// import {
//     getUserEmailDetails,
//     getMembershipEmailDetails,
// } from 'libs/confirmEmailHelpers';
// Use alias to avoid conflicting name of tab and divisions
import { BenefitStatements } from '../cards/BenefitStatements';
import { RebateTaxStatements } from '../cards/RebateTaxStatements';
import {
    setEmailConfirmationModalVisible,
} from 'store';
import ConfirmEmailModal from 'components/modals/ConfirmEmailModal/ConfirmEmailModal';

export const Statements = () => {
    const confirmEmailModalVisible = useSelector(
        (state) => state.ui.emailConfirmationModalVisible,
    );
    const dispatch = useDispatch();

    const [confirmFunc, setConfirmFunc] = useState(() => {});

    return (
        <>
            <Tab.Pane eventKey="statements">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <BenefitStatements setConfirmFunc={setConfirmFunc} />
                    </div>
                    <div className="col-12 col-md-6 mt-4 mt-md-0">
                        <RebateTaxStatements setConfirmFunc={setConfirmFunc} />
                    </div>
                </div>
            </Tab.Pane>
            {/* MODALS */}
            <ConfirmEmailModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'confirmemail',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    dispatch(setEmailConfirmationModalVisible(false));
                }}
                handleConfirm={confirmFunc}
                show={confirmEmailModalVisible}
                align={'right'}></ConfirmEmailModal>
        </>
    );
};
