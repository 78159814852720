import { Button, Card } from 'react-bootstrap';
import React, { useState } from 'react';
import ExtrasInfoMobileModal from './ExtrasInfoMobileModal';
import gtagEvent from 'libs/gtagEvent';
import ExtrasLimitsModal from 'components/modals/ExtrasLimitsModal/ExtrasLimitsModal';
import LabelledTitle from 'components/elements/labels/LabelledTitle';
import { useSelector } from 'react-redux';
import { MdChevronRight } from 'react-icons/md';

const WithExtrasMobile = ({ topExtrasBars, currentUserBenefits }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [extrasModalVisible, setExtrasModalVisible] = useState(false);
  const [currentExtraBenefit, setCurrentExtraBenefit] = useState(false);
  const product = useSelector((state) => state.product);

  const handleExtraClick = (extra, name) => {
    setExtrasModalVisible(true);
    const extraName = name || extra.name;
    const eventName = extraName?.toLowerCase().replace(' ', '_') || 'extra_cover';

    gtagEvent({
      screen: 'overview',
      action: eventName + '_tile',
      label: `Clicked on a ${extraName} button`,
      type: 'modal_open',
    });
    setCurrentExtraBenefit({
      ...extra,
      eventName,
      name: extraName,
    });
  };

  return (
    <>
      <Card className="p-4 mt-4">
        <LabelledTitle label="Extras Cover" title={product?.extrasName} />
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                variant="secondary"
                onClick={() => {
                    setModalOpen(true);
                }}>
                Details
                <MdChevronRight
                    size={20}
                    style={{
                        verticalAlign: 'middle',
                        marginTop: '-2px',
                    }}
                    />
            </Button>
        </div>
      </Card>
      <ExtrasInfoMobileModal
        topExtrasBars={topExtrasBars}
        currentUserBenefits={currentUserBenefits}
        handleClose={() => setModalOpen(false)}
        handleExtraClick={handleExtraClick}
        show={modalOpen}
      />
      <ExtrasLimitsModal
        title={
          currentExtraBenefit.name
            ? currentExtraBenefit.name
            : currentExtraBenefit.Description
        }
        show={extrasModalVisible}
        handleClose={() => {
          gtagEvent({
            screen: currentExtraBenefit.eventName,
            action: 'cancel',
            label: 'Cancelled or closed modal',
            type: 'modal',
          });
          setExtrasModalVisible(false);
          setCurrentExtraBenefit({});
        }}
        currentExtraBenefit={currentExtraBenefit}
      />
    </>
  );
};

export default WithExtrasMobile;
