import ExtrasTileGeneralDental from "img/vector/ExtrasTileGeneralDental/comp";
import ExtrasTileMajorDental from "img/vector/ExtrasTileMajorDental/comp";
import ExtrasTileOptical from "img/vector/ExtrasTileOptical/comp";
import React from "react";

interface CoverIconProps {
    name: string;
    width?: number;
    height?: number;
}


const CoverIcon = ({name, width, height}: CoverIconProps) => {
    if (name == 'General Dental' || name == 'Dental'){
        return <ExtrasTileGeneralDental width={width} height={height} />
    }

    if (name == 'Major Dental'){
        return <ExtrasTileMajorDental width={width} height={height} />
    }

    if (name == 'Optical'){
        return <ExtrasTileOptical width={width} height={height} />
    }

    return null
}

export default CoverIcon;