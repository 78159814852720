import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import React, { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import gtagEvent from 'libs/gtagEvent';
import { WellbeingBenefit } from 'types/wellbeingBenefits';

const WellbeingPage: React.FC = () => {
    const [benefits, setBenefits] = useState<WellbeingBenefit[]>([]);

    useEffect(() => {
        benefitGet();
    }, []);

    const [benefitGetState, benefitGet] = useAsyncFn(async () => {
        const memberid = getMembershipId();
        const access_token = getAccessToken();
        try {
            if (!memberid) {
                throw new Error('Member ID not found');
            }
            const response = await api
                .website(CONFIG, access_token)
                .wellbeingBenefits(memberid);
            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                console.log(response);
                setBenefits(response);
            }
        } catch (error) {
            console.log({ benefitGetState });
            console.log('ERROR');
            console.log(error);
        }
    });

    return (
        <main className="container-fluid p-4 wellbeing-page">
            <div className="row">
                <div className="container">
                    <div className="row flex-wrap">
                        <div className="col-12 py-5 col-md-10 offset-md-1">
                            <h1>Wellbeing Benefits</h1>
                            <h2>
                                Take advantage of exclusive member discounts on the brands you love.
                            </h2>
                            <p className="text-center">
                                At TUH we&apos;re all about giving more back to members and partnering with these fantastic 
                                brands is yet another way we&apos;re delivering more value to you.
                            </p>
                        </div>
                        <div className="w-100"></div>
                        {benefits.length > 0 &&
                            benefits.map((item, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="col-12 col-md-6 col-lg-4 item">
                                        <div className="inner">
                                            <div className="display-image">
                                                <img
                                                    src={
                                                        item?.display_image?.url
                                                    }
                                                    className="img-fluid"
                                                    alt={
                                                        item?.display_image?.alt
                                                    }
                                                />
                                            </div>
                                            <div className="title">
                                                <h3>{item.title}</h3>
                                            </div>
                                            <div
                                                className="body"
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.member_body,
                                                }}
                                                onClick={() => {
                                                    gtagEvent({
                                                        screen: 'wellbeing',
                                                        action: `referralfor_${item.title
                                                            .toLowerCase()
                                                            .replace(
                                                                ' ',
                                                                '_',
                                                            )}`,
                                                        label: `User clicked on the benefit card for ${item.title}`,
                                                    });
                                                    gtagEvent({
                                                        screen: 'wellbeing',
                                                        action: `referral_clicked`,
                                                        label: `User clicked on a wellbeing referral link.`,
                                                    });
                                                }}></div>
                                            <div className="logo">
                                                <img
                                                    src={item?.logo?.url}
                                                    className="img-fluid"
                                                    alt={item?.logo?.alt}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default WellbeingPage;
