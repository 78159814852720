import CardAltDivision from 'components/elements/divisions/CardAltDivision';
import CardHorizontalRule from 'components/elements/divisions/CardHorizontalRule';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import { Base64 } from 'js-base64';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import gtagEvent from 'libs/gtagEvent';
import { allPersons, mainPerson } from 'libs/helpers';
import React, { useState } from 'react';
import { Button, Card, Form, Spinner, Table } from 'react-bootstrap';
import { IoMdDocument } from 'react-icons/all';
import { useDispatch, useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { setConfirmingPerson } from 'store';
import { setEmailConfirmationModalVisible } from 'store';
import styled from 'styled-components';
import api from '@qldtuh/tuh-uh-js-api';

const StyledTable = styled(Table)`
    td:not(:first-child),
    th:not(:first-child) {
        text-align: right;
    }
    td,
    th {
        padding: 0.75rem 2rem;
    }
`;

export const BenefitStatements = ({ setConfirmFunc }) => {
    /**
     * setConfirmFunc sends the appropriate getStatement()
     * up to the Statements Tab component which passes it
     * to the confirm email modal to be able to download
     * the right PDF after confirming email. --xxKeefer
     */
    const membership = useSelector((state) => state.membership);

    const emailsConfirmed = useSelector(
        (state) => state.emailConfirmation.confirmed,
    );

    const confirmForUserId = useSelector(
        (state) => state.emailConfirmation.confirmFor,
    );

    const isEmailConfirmed = emailsConfirmed.includes(parseInt(confirmForUserId));

    const dispatch = useDispatch();

    const allPeople = allPersons(membership);
    const mainMember = mainPerson(membership);

    const [benefitStatementPerson, setBenefitStatementPerson] = useState('');
    const [personName, setPersonName] = useState('');

    function getCurrentFiscalYear() {
        //get current date
        var today = new Date();

        //get current month
        var curMonth = today.getMonth();

        var fiscalYr = '';
        if (curMonth > 5) {
            //Check if July
            fiscalYr = today.getFullYear();
        } else {
            fiscalYr = today.getFullYear() - 1;
        }

        return fiscalYr;
    }

    const yearAgoFiscalYear = getCurrentFiscalYear();
    const twoYearsAgoFiscalYear = getCurrentFiscalYear() - 1;

    const [errorSpace, setErrorSpace] = useState(<>&nbsp;</>);
    const [failedAttempts, setFailedAttempts] = useState(0);

    const [getStatementState, getStatement] = useAsyncFn(
        async (personId, benefitStatementYear, personName) => {
            setFailedAttempts(0);

            const access_token = getAccessToken();
            const memberid = getMembershipId();

            const data = {
                personid: personId,
                type: 'benefit',
                benefityear: benefitStatementYear,
            };

            if (personId === 'all') {
                delete data.personid;
            }

            console.log('DATA:', data);

            try {
                const response = await api
                    .user(CONFIG, access_token)
                    .membership.getCommunications(memberid, data);

                if (response?.status === 'error') {
                    console.log(response);
                    throw new Error(response.message);
                } else {
                    if (response?.Document) {
                        console.log('Got a pdf');
                        console.log(response);

                        setFailedAttempts(0);
                        var downloadLink = document.createElement('a');
                        downloadLink.target = '_blank';
                        downloadLink.download =
                            personName.toLowerCase() +
                            '_benefit_statement_' +
                            personId +
                            '_' +
                            benefitStatementYear +
                            '.pdf';

                        // convert downloaded data to a Blob
                        let unEncodedDoc = Base64.toUint8Array(
                            response.Document,
                        );
                        var blob = new Blob([unEncodedDoc], {
                            type: 'application/pdf',
                        });

                        // create an object URL from the Blob
                        var URL = window.URL || window.webkitURL;
                        var downloadUrl = URL.createObjectURL(blob);

                        // set object URL as the anchor's href
                        downloadLink.href = downloadUrl;

                        // append the anchor to document body
                        document.body.appendChild(downloadLink);

                        // fire a click event on the anchor
                        downloadLink.click();
                        gtagEvent({
                            screen: 'membership',
                            action: 'benefitstatement',
                            label: 'Retrieved benefit statement',
                        });

                        // cleanup: remove element and revoke object URL
                        document.body.removeChild(downloadLink);
                        URL.revokeObjectURL(downloadUrl);
                    } else {
                        console.log(response);
                    }
                }
            } catch (response) {
                console.log('ERROR');
                console.log(response.message);
                setFailedAttempts(1);
                setErrorSpace(
                    response.message || 'Could not connect to server',
                );
                gtagEvent({
                    screen: 'membership',
                    action: 'benefitstatement_error',
                    label: 'Could not retrieve benefit statement',
                });
            }
        },
    );

    return (
        <>
            <Card className="py-4">
                <h1 className="px-4 mb-4 text-primary text-uppercase">
                    Benefit Statements
                </h1>
                <div className="my-3">
                    <CardHorizontalRule />
                </div>
                <div className="px-4 mb-4" style={{ fontSize: '14px' }}>
                    <p>
                        To view or print your Benefit Statement, please select a
                        person below.
                    </p>
                    <p>
                        Benefit Statements for the previous two financial years
                        are available online. For any earlier statements please{' '}
                        <Link
                            to="/member/contact"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'membership',
                                    action: 'contactus',
                                    label: 'clicked contact us link from membership details',
                                });
                            }}>
                            contact us.
                        </Link>
                    </p>
                </div>
                <div className="mx-1">
                    <CardAltDivision>
                        <div className="p-4 d-flex justify-content-between align-items-center flex-column flex-md-row">
                            <div className="mr-4 flex-shrink-1 col-12 col-md-6">
                                Show Benefit Statements for:
                            </div>
                            <div className="flex-grow-1 col-12 col-md-6">
                                <Form.Control
                                    as="select"
                                    value={benefitStatementPerson}
                                    disabled={!allPeople}
                                    onChange={(e) => {
                                        setFailedAttempts(0);
                                        gtagEvent({
                                            screen: 'membership',
                                            action: 'dropdown_benefitstatement',
                                            label: 'selected statement dropdown',
                                        });

                                        if (e.target.value !== 'all') {
                                            dispatch(
                                                setConfirmingPerson(
                                                    e.target.value,
                                                ),
                                            );
                                            setBenefitStatementPerson(
                                                e.target.value,
                                            );
                                            const person = allPeople.find(
                                                (person) =>
                                                    person?.Person?.PersonId.toString() ===
                                                    e.target.value,
                                            );
                                            setPersonName(
                                                `${person?.Person?.GivenName}_${person?.Person?.Surname}`,
                                            );
                                        } else {
                                            dispatch(
                                                setConfirmingPerson(
                                                    mainMember.Person.PersonId,
                                                ),
                                            );
                                            setBenefitStatementPerson("all");
                                            setPersonName('All_Persons');
                                        }
                                    }}>
                                    <option value="">
                                        {!allPeople
                                            ? '- Loading... -'
                                            : '- Select Person -'}
                                    </option>
                                    <option value="all">All persons</option>
                                    {allPeople
                                        ? allPeople.map((person, idx) => (
                                              <option
                                                  key={idx}
                                                  value={
                                                      person?.Person?.PersonId
                                                  }>
                                                  {person?.Person?.GivenName}{' '}
                                                  {person?.Person?.Surname}
                                              </option>
                                          ))
                                        : ''}
                                </Form.Control>
                            </div>
                        </div>
                    </CardAltDivision>
                </div>
                <div className="mx-1">
                    <StyledTable
                        className=""
                        responsive
                        striped
                        style={{
                            marginBottom: '0px',
                            display:
                                benefitStatementPerson !== ''
                                    ? 'table'
                                    : 'none',
                        }}>
                        <thead>
                            <tr>
                                <th className="font-weight-medium text-uppercase">
                                    Person
                                </th>
                                <th className="font-weight-medium text-uppercase">
                                    Retrieve Statement
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={`claim_row_1`} className={'table-link'}>
                                <td
                                    className={
                                        'd-flex align-items-center flex-grow-1 font-weight-medium'
                                    }
                                    style={{
                                        fontSize: '14px',
                                    }}>
                                    Current Financial Year - {yearAgoFiscalYear}
                                    /
                                    {(yearAgoFiscalYear + 1)
                                        .toString()
                                        .substring(2)}
                                </td>
                                <td
                                    className=""
                                    style={{ verticalAlign: 'middle' }}>
                                    <Button
                                        variant="link"
                                        className="text-primary-dark-1"
                                        style={{
                                            padding: '0px',
                                        }}
                                        onClick={() => {
                                            if (!isEmailConfirmed) {
                                                // setConfirmFunc sends the getStatement function up a component to be passed onto the confirm email modal
                                                setConfirmFunc(() => {
                                                    return () =>
                                                        getStatement(
                                                            benefitStatementPerson,
                                                            yearAgoFiscalYear,
                                                            personName,
                                                        );
                                                });
                                                gtagEvent({
                                                    screen: 'membership',
                                                    action: 'confirm_email_address',
                                                    label: 'Was prompted to confirm email',
                                                    type: 'modal_open',
                                                });
                                                dispatch(
                                                    setEmailConfirmationModalVisible(
                                                        true,
                                                    ),
                                                );
                                            } else {
                                                getStatement(
                                                    benefitStatementPerson,
                                                    yearAgoFiscalYear,
                                                    personName,
                                                );
                                            }
                                        }}>
                                        <IoMdDocument
                                            style={{ verticalAlign: 'middle' }}
                                            size={20}
                                        />{' '}
                                        {yearAgoFiscalYear}/
                                        {(yearAgoFiscalYear + 1)
                                            .toString()
                                            .substring(2)}{' '}
                                        Benefit Statement
                                    </Button>
                                </td>
                            </tr>
                            <tr key={`claim_row_2`} className={'table-link'}>
                                <td
                                    className={
                                        'd-flex align-items-center flex-grow-1 font-weight-medium'
                                    }
                                    style={{
                                        fontSize: '14px',
                                    }}>
                                    Previous Financial Year -{' '}
                                    {twoYearsAgoFiscalYear}/
                                    {(twoYearsAgoFiscalYear + 1)
                                        .toString()
                                        .substring(2)}
                                </td>
                                <td
                                    className=""
                                    style={{ verticalAlign: 'middle' }}>
                                    <Button
                                        variant="link"
                                        className="text-primary-dark-1"
                                        style={{
                                            padding: '0px',
                                        }}
                                        onClick={() => {
                                            if (!isEmailConfirmed) {
                                                setConfirmFunc(() => {
                                                    return () =>
                                                        getStatement(
                                                            benefitStatementPerson,
                                                            twoYearsAgoFiscalYear,
                                                            personName,
                                                        );
                                                });
                                                gtagEvent({
                                                    screen: 'membership',
                                                    action: 'confirm_email_address',
                                                    label: 'Was prompted to confirm email',
                                                    type: 'modal_open',
                                                });
                                                dispatch(
                                                    setEmailConfirmationModalVisible(
                                                        true,
                                                    ),
                                                );
                                            } else {
                                                gtagEvent({
                                                    screen: 'membership',
                                                    action: 'benefitstatement',
                                                    label: 'Retrieved statement after email confirm',
                                                });
                                                getStatement(
                                                    benefitStatementPerson,
                                                    twoYearsAgoFiscalYear,
                                                    personName,
                                                );
                                            }
                                        }}>
                                        <IoMdDocument
                                            style={{ verticalAlign: 'middle' }}
                                            size={20}
                                        />{' '}
                                        {twoYearsAgoFiscalYear}/
                                        {(twoYearsAgoFiscalYear + 1)
                                            .toString()
                                            .substring(2)}{' '}
                                        Benefit Statement
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </StyledTable>
                </div>
                <div className="text-center">
                    {getStatementState.loading && (
                        <Spinner
                            animation="border"
                            role="status"
                            as="span"
                            size="lg"
                        />
                    )}
                    <div style={{ paddingInline: '10px' }}>
                        <Fade collapse bottom when={failedAttempts > 0}>
                            <StatusNotificationCard status="error">
                                {errorSpace}
                            </StatusNotificationCard>
                        </Fade>
                    </div>
                </div>
            </Card>
        </>
    );
};
